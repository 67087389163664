@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body,
html {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.right-chat-msg {
    position: absolute;
    right: 10px;
}

.margin-top-10 {
    margin-top: 10px;
}

option:disabled {
    color: lightgray;
}

.chat-input>input:disabled {
    opacity: .5;
}

.chat-input>button:disabled,
.chat-input>div>button:disabled {
    opacity: .5;
    cursor: not-allowed;
}

.center-loader {
    position: relative;
}

.center-loader>div {
    position: absolute;
    left: 46%;
    margin-top: 50%;
}

.clickable {
    cursor: pointer;
}

/* Absolute Center Spinner */
.loading-screen {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading-screen:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));

    background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading-screen:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.border-color-gray {
    border-color: blueviolet !important;
}